module.exports = [{
      plugin: require('../.yarn/$$virtual/gatsby-plugin-matomo-virtual-e0ad05761f/0/cache/gatsby-plugin-matomo-npm-0.8.3-d613c15907-9d57d9285f.zip/node_modules/gatsby-plugin-matomo/gatsby-browser.js'),
      options: {"plugins":[],"siteId":"3","matomoUrl":"https://stats.ryankes.eu","siteUrl":"https://ryankes.eu"},
    },{
      plugin: require('../.yarn/$$virtual/gatsby-background-image-virtual-89619a42bb/0/cache/gatsby-background-image-npm-1.2.0-6ac5999142-a9917a4ff4.zip/node_modules/gatsby-background-image/gatsby-browser.js'),
      options: {"plugins":[],"specialChars":"/:"},
    },{
      plugin: require('../.yarn/$$virtual/gatsby-plugin-offline-virtual-7b27fd7bd0/0/cache/gatsby-plugin-offline-npm-3.4.0-5716c1d04e-477827496d.zip/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../.yarn/$$virtual/gatsby-plugin-google-analytics-virtual-1bf823f3f6/0/cache/gatsby-plugin-google-analytics-npm-2.5.0-e4eb164d85-1215966b7b.zip/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-50843119-1","head":false,"anonymize":false,"respectDNT":false,"exclude":[],"pageTransitionDelay":0},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
